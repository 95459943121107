import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app-peter';
  pdfSrc = "/assets/Menu2021_compressed.pdf";
  zoom = 0.8;
}
